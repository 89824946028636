import axios from 'axios'
import {endpoint} from '@/helpers/problemator.js'

// Check if token is in local storage
let  token = localStorage.getItem('authToken');

if (token == undefined) {
  token = null
}

axios.defaults.headers.common = {'Authorization': `bearer ${token}`}


export default  {
    delete: async (payload) => {
        const ret = await axios.delete(endpoint + "floorplans/"+payload)
        return ret.data
    },
    create: async (payload) => {
        const ret = await axios.post(endpoint + "floorplans",payload)
        return ret.data
    },
    save: async (payload) => {
        const ret = await axios.post(endpoint + "floorplans/?id="+payload.id,payload)
        return ret.data
    },
    get: async (payload) => {
        const ret = await axios.get(endpoint + "floorplans/"+payload)
        return ret.data
    },
    all : async () => {
        const ret = await axios.get(endpoint + "floorplans")
        return ret.data
    },
}