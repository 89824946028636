import api from '@/api'
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)


export default {
  namespaced: true,
  state: () => ({
    climberAscents: [],
    competition : null,
    judgingCompetition : null,
    currentlyClimbing : [],
    competitions : [],
    latestAscents : [],
    results : null,
    problemAscents : [], // Stores just a single problem's ascents at a time
    contender : null,
    hits: [],
    searchHits : [],
  }),
  mutations: {
    latest_ascents (state, payload) {
      state.latestAscents = payload
    },
    judging_competition(state, competition) {
      state.judgingCompetition = competition
    },
    results(state, payload) {
      state.results = payload
    },
    addCompetition(state, payload) {
      state.competitions = [...state.competitions, payload]
    },
    updateJudges(state, payload) {
      state.competition.judges = payload
    },
    updateCompetition(state, payload) {
      state.competition= {...state.competition,...payload}
    },
    problemAscents(state, payload) {
      state.problemAscents= payload
    },
    replaceCompetitionProblems(state, payload) {
      state.competition.problems = [ ...payload ]
    },
    currentlyClimbing(state, payload) {
      state.currentlyClimbing = payload 
    },
    addNewCategory(state, payload) {
      state.competition.categories= [...state.competition.categories, payload]
    },
    updateCategoryInComp(state, payload) {
      state.competition.categories= state.competition.categories.map(x => {
        if (x.pivot.id == payload.pivot.id) {
          return {...x, pivot : payload.pivot}
        }
        return x
      })
    },
    removeCategoryFromComp(state, payload ) {
      state.competition.categories = state.competition.categories.filter(x => x.pivot.id != payload)
    },
    removeProblemFromComp(state, payload ) {
      state.competition.problems = state.competition.problems.filter(x => x.pivot.id != payload)
    },
    addCategoryInComp(state, payload) {
      state.competition.categories = [...state.competition.categories,payload]
    },
    changeProblemInComp(state, payload) {
      state.competition.problems = state.competition.problems.map(x => {
        if (x.pivot.id == payload.id) {
          return {...x, pivot : payload}
        }
        return x
      })
    },
    saveContenderExtraInfo(state, payload) {
      if (payload.paid) {
        state.competition.paidregistrations =   state.competition.paidregistrations.map(x => {
            if (x.pivot.id == payload.id) {
              return  {...x, ['pivot'] : payload}
            }
            return x
        })
      } else {
        state.competition.unpaidregistrations =  state.competition.unpaidregistrations .map(x => {
            if (x.pivot.id == payload.id) {
              return  {...x, ['pivot'] : payload}
            }
            return x
        })
      }
    },
    addContenderToCompetition(state, payload) {
      if (payload.target_list_paid) {
        state.competition.paidregistrations = [ payload, ...state.competition.paidregistrations]
      } else {
        state.competition.unpaidregistrations = [payload, ...state.competition.unpaidregistrations ]
      }
    },
    searchHits(state, payload) {
      state.searchHits = payload
    },
    removeContender(state, payload) {
      state.competition.paidregistrations = state.competition.paidregistrations.filter(x => x.pivot.id != payload.id)
      state.competition.unpaidregistrations = state.competition.unpaidregistrations.filter(x => x.pivot.id != payload.id)
    },
    movetounpaidlist(state, payload) {
      // Take the contender and move from list to another.
      const contenderToMove = state.competition.paidregistrations.find(x => x.id == payload.id)
      state.competition.paidregistrations = state.competition.paidregistrations.filter(x => x.id != payload.id)
      state.competition.unpaidregistrations = [contenderToMove, ...state.competition.unpaidregistrations]
    },
    movetopaidlist(state, payload) {
      // Take the contender and move from list to another.
      const contenderToMove = state.competition.unpaidregistrations.find(x => x.id == payload.id)
      state.competition.unpaidregistrations = state.competition.unpaidregistrations.filter(x => x.id != payload.id) 
      state.competition.paidregistrations = [contenderToMove, ...state.competition.paidregistrations]
    },
    compPaidregistrations(state, payload) {
      state.competition.paidregistrations = state.competition.paidregistrations.map(x => {
         if (x.id == payload.id ) {
           return payload
         }
        return x
      })  
    },
    compUnpaidregistrations(state, payload) {
      state.competition.unpaidregistrations = state.competition.unpaidregistrations.map(x => {
         if (x.id == payload.id ) {
           return payload
         }
        return x
      })  
    },
    contender(state, payload) {
      state.contender = payload
    },
    deleteClimberAscent(state, payload) {
      state.climberAscents = state.climberAscents.filter(x => x.tickid != payload.tickid)
    },
    competitions (state, payload) {
      state.competitions = payload
    },
    competition (state, payload) {
      state.competition = payload
    },
    climberAscents(state, payload) {
      state.climberAscents = payload
    },
    hits(state, payload) {
      state.hits = payload
    },

  },
  actions: {
    async getLatestAscents({commit}, payload ) {
      const ret = await api.competitions.getLatestAscents(payload)
      commit('latest_ascents',ret)
      return ret
    },
    async sendFinalists({commit}, payload ) {
      console.log(commit)
      const ret = await api.competitions.sendFinalists(payload)
      return ret
    },
    async addNewCompetition({commit} ) {
      const ret = await api.competitions.addNewCompetition()
      commit('addCompetition',ret)
      return ret
    },
    async attachJudges({commit} , payload) {
      const ret = await api.competitions.attachJudges(payload)
      commit('updateJudges',ret) // Assume contender has paid the fee
      return ret
    },
    // eslint-disable-next-line no-unused-vars
    async deleteAllTicks({commit} , payload) {
      const ret = await api.competitions.deleteAllTicks(payload)
      commit('updateCompetition',ret) // Assume contender has paid the fee
      return ret
    },
    async saveCompetition({commit} , payload) {
      const ret = await api.competitions.saveCompetition(payload)
      commit('updateCompetition',ret) // Assume contender has paid the fee
      return ret
    },
    async markPrizeReceived({commit} , payload) {
      const ret = await api.competitions.markPrizeReceived(payload)
      commit('compPaidregistrations',ret) // Assume contender has paid the fee
      return ret
    },
    async showProblemAscents({commit} , payload) {
      const ret = await api.competitions.getProblemAscents(payload)
      commit('problemAscents',ret)
      return ret
    },
    async attachProblemsToComp({commit} , payload) {
      // This always returns the whole set of attached problems
      const ret = await api.competitions.attachProblemsToComp(payload)
      commit('replaceCompetitionProblems',ret)
      return ret
    },
    async getSharedGymProblems({commit} , payload) {
      const ret = await api.competitions.getSharedGymProblems(payload)
      commit('getSharedGymProblems',ret)
      return ret
    },
    async updateCategoryInComp({commit} , payload) {
      const ret = await api.competitions.updateCategoryInComp(payload)
      commit('updateCategoryInComp',ret)
      return ret
    },

    async addNewCategory({commit} , payload) {
      const ret = await api.competitions.addNewCategory(payload)
      commit('addNewCategory',ret)
      return ret
    },
    async endAllClimbing({commit} , payload) {
      const ret =  await api.competitions.endAllClimbing(payload)
      commit('currentlyClimbing',ret.currently_climbing)
    },
    async getCurrentlyClimbing({commit} , payload) {
      const ret =  await api.competitions.getCurrentlyClimbing(payload)
      commit('currentlyClimbing',ret.currently_climbing)
    },
    async endClimbing({commit} , payload) {
      console.log(commit)
      return await api.competitions.endClimbing(payload)
    },
    async startClimbing({commit} , payload) {
      console.log(commit)
      return await api.competitions.startClimbing(payload)
    },
    async assignCategoryToComp({commit} , payload) {
      const ret = await api.competitions.assignCategoryToComp(payload)
      commit('addCategoryInComp',ret)
      return ret
    },
    async removeProblemFromComp({commit}, payload) {
      await api.competitions.removeProblemFromComp(payload)
      commit('removeProblemFromComp',payload.id)
      return payload.id
    },
    async removeCategoryFromComp({commit}, payload) {
      return api.competitions.removeCategoryFromComp(payload)
      .then(() => {
        commit('removeCategoryFromComp',payload.id)
        return payload.id
      })
    },
    async changeProblemInComp({commit} , payload) {
      const ret = await api.competitions.changeProblemInComp(payload)
      commit('changeProblemInComp',ret)
      return ret
    },
    async sendPaymentEmail(state , payload) {
      const ret = await api.competitions.sendPaymentEmail(payload)
      return ret
    },
    async saveContenderExtraInfo({commit} , payload) {
      const ret = await api.competitions.saveContenderExtraInfo(payload)
      commit('saveContenderExtraInfo',ret)
      return ret
    },

    async addContenderToCompetition({commit} , payload) {
      const ret = await api.competitions.addContenderToCompetition(payload)
      const retval = {...ret,['id'] : ret.contenderid}
      commit('addContenderToCompetition',{...retval, ['target_list_paid'] : payload.target_list_paid})
      return retval
    },
    async searchForClimber({commit} , payload) {
      const ret = await api.competitions.searchForClimber(payload)
      commit('searchHits',ret)
    },
    removeContender({commit}, payload) {
       return api.competitions.removeContender(payload)
      .then((json) => {
        commit('removeContender',payload)
        return json
      })
    },
    async moveToUnpaidList({commit}, payload) {
      await api.competitions.moveToUnpaidList(payload)
      commit('movetounpaidlist',payload)
    },
    async moveToPaidList({commit}, payload) {
      await api.competitions.moveToPaidList(payload)
      commit('movetopaidlist',payload)

    },
    async saveContender({commit}, payload) {
      let ret = null
      if (payload.id != '*new*') {
        ret = await api.competitions.updateContender(payload)
      } else {
        ret = await api.competitions.saveContender(payload)
      }
      commit('contender',ret)
      // Update also contender in competition object
      switch (payload.type) {
        case 'paidregistrations':
            commit('compPaidregistrations',ret)
          break
        case 'unpaidregistrations':
            commit('compUnpaidregistrations',ret)
          break
      }
    },
    async getContender({commit}, payload) {
      const ret = await api.competitions.getContender(payload)
      commit('contender',ret)
    },
    removeAscentJudging({commit}, payload) {
      return api.competitions.removeAscentJudging(payload)
      .then((ret) => {
        commit('deleteClimberAscent',payload)
        return ret 
      })

    },
    async removeAscent({commit}, payload) {
      const ret = await api.competitions.removeAscent(payload)
      commit('deleteClimberAscent',payload)
      return ret 
    },
    async addAscentJudging({commit}, payload) {
      const ret = await api.competitions.addAscentJudging(payload)
      commit('climberAscents',ret.ascents)
      return ret
    },
    async addAscent({commit}, payload) {
      const ret = await api.competitions.addAscent(payload)
      commit('climberAscents',ret.ascents)
      return ret
    },
    async getResults({commit}, payload) {
      let ret = await api.competitions.getResults(payload)
      commit('results',ret)
    },
    async getPublicJudging({commit}, payload) {
      let ret = await api.competitions.getJudgingCompetition(payload)
      commit('judging_competition',ret)
      return ret
    },
    async getCompetition({commit}, payload) {
      let ret = await api.getCompetition(payload)
      // Convert dates to local timezone
      ret.compdate = dayjs.utc(ret.compdate).format()
      ret.timespan_start = dayjs.utc(ret.timespan_start).format()
      ret.timespan_end = dayjs.utc(ret.timespan_end).format()
      ret.registration_start = dayjs.utc(ret.registration_start).format()
      ret.registration_end = dayjs.utc(ret.registration_end).format()
      commit('competition',ret)
    },
    async getCompetitions({commit}, payload) {
      const ret = await api.getCompetitions(payload)
      commit('competitions',ret)
    },
    async searchContender({ commit }, payload) {
      const ret = await api.competitions.searchContender(payload)
      commit('hits', ret)
    },
    async getClimberAscentsJudging({ commit }, payload) {
      const ret = await api.competitions.getClimberAscentsJudging(payload)
      commit('climberAscents', ret)
    },
    async getClimberAscents({ commit }, payload) {
      const ret = await api.competitions.getClimberAscents(payload)
      commit('climberAscents', ret)
    },
  },
  getters: {}
}
