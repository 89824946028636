import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    all : [],
    row : null,
  }),
  mutations: {

  },
  actions: {
    async fetchRoutesetterReport({commit}, payload) {
      console.log(commit)
        const ret = await api.reports.routesetter_report(payload)
        return ret
    },
  },
  getters: {}
}
