import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    all : [],
    cup : null,
  }),
  mutations: {
    deleteCup(state, payload) {
      state.all= state.all.filter(x => x.id != payload)
    },
    updateCup(state, payload) {
      state.cup= {...state.cup,...payload}
    },
    cup(state, payload) {
      state.cup= payload
    },
    cups(state, payload) {
      state.all = payload
    },
    addCup(state, payload)  {
      state.all = [ payload, ...state.all ]
    },
    updateCups(state, payload) {
      state.all= state.all.map(x => {
        if (x.id == payload.id) {
          return payload
        }
        return x
      })
    },

  },
  actions: {
    async saveCup({commit}, payload) {
      let ret = null
      if (payload.id == '*new*') {
        ret = await api.cups.createCup(payload)
        commit('addCup',ret.cup)
      } else {
        ret = await api.cups.saveCup(payload)
        commit('updateCups',ret.cup)
      }
      commit('cup',ret.cup)
    },
    async deleteCup({commit}, payload) {
      await api.cups.deleteCup(payload.id)
      commit('deleteCup',payload.id)
    },
    async getCup({commit}, payload) {
      const ret = await api.cups.getCup(payload)
      commit('cup',ret)
    },
    async all({commit} ) {
      const ret = await api.cups.getCups()
      commit('cups',ret)
    },
  },
  getters: {}
}