import axios from 'axios'
import {endpoint, publicendpoint} from '@/helpers/problemator.js'

// Check if token is in local storage
let  token = localStorage.getItem('authToken');

if (token == undefined) {
  token = null
}

axios.defaults.headers.common = {'Authorization': `bearer ${token}`}


export default  {

    async endAllClimbing(payload) {
      const ret =  await axios.post(publicendpoint+"judging/"+payload.compid+"/end_all_climbing/")
      return ret.data
    },
    async getCurrentlyClimbing(payload) {
      const ret =  await axios.get(publicendpoint+"judging/"+payload.compid+"/get_currently_climbing")
      return ret.data
    },
    async endClimbing(payload) {
      const ret =  await axios.post(publicendpoint+"judging/"+payload.compid+"/climbers/"+payload.contid+"/climb_end",payload)
      return ret.data
    },
    async startClimbing(payload) {
      const ret =  await axios.post(publicendpoint+"judging/"+payload.compid+"/climbers/"+payload.contid+"/climb_start",payload)
      return ret.data
    },
    async removeAscentJudging(payload) {
      const ret =  await axios.delete(publicendpoint+"judging/"+payload.compid+"/ascents/"+payload.tickid+"?key="+payload.key)
      return ret.data
    },
    async getJudgingCompetition(payload) {
      const ret = await axios.post(publicendpoint + "judging/"+payload.compid, payload)
      return ret.data
    },
    async getClimberAscentsJudging(payload) {
      const ret =  await axios.get(publicendpoint+"judging/"+payload.compid+"/ascents/"+payload.contid)
      return ret.data
    },
    async addAscentJudging(payload) {
      const ret =  await axios.post(publicendpoint+"judging/"+payload.comp_id+"/ascents",payload)
      return ret.data
    },
    async getLatestAscents(payload) {
      const ret =  await axios.post(endpoint+"competitions/"+payload.compid+"/ascents/latest")
      return ret.data
    },
    async addNewCompetition() {
      const ret = await axios.post(endpoint + "competitions")
      return ret.data
    },
    async attachJudges(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/attach_judges",payload)
      return ret.data
    },
    async deleteAllTicks(payload) {
      const ret = await axios.delete(endpoint + "competitions/"+payload.compid+"/ascents")
      return ret.data
    },
    async saveCompetition(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid,payload.competition)
      return ret.data
    },
    async getProblemAscents(payload) {
      const ret = await axios.get(endpoint + "competitions/"+payload.compid+"/problems/"+payload.problemid+"/ascents",payload)
      return ret.data
    },
    async attachProblemsToComp(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/problems/attachProblems",payload)
      return ret.data
    },
    async updateCategoryInComp(payload) {
      const ret = await axios.put(endpoint + "competitions/"+payload.compid+"/categories/",payload)
      return ret.data
    },
    async addNewCategory(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/categories/new",payload)
      return ret.data
    },
    async assignCategoryToComp(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/categories/",payload)
      return ret.data
    },
    async removeCategoryFromComp(payload) {
      const ret = await axios.delete(endpoint + "competitions/"+payload.compid+"/categories/"+payload.id)
      return ret.data
    },
    async removeProblemFromComp(payload) {
      const ret = await axios.delete(endpoint + "competitions/"+payload.compid+"/problems/"+payload.id)
      return ret.data
    },
    async changeProblemInComp(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/problems/changepivot",payload)
      return ret.data
    },
    async sendPaymentEmail(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/participations/"+payload.participation_id+"/sendpaymentemail",payload)
      return ret.data
    },
    async saveContenderExtraInfo(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/contenders/extrainfo",payload)
      return ret.data
    },
    async addContenderToCompetition(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/contenders",payload)
      return ret.data
    },
    async searchForClimber(payload) {
      const ret = await axios.post(endpoint + "competitions/contenders/search",payload)
      return ret.data
    },
    async removeContender(payload) {
      return axios.delete(endpoint + "competitions/"+payload.compid+"/contenders/"+payload.id+"/removefromcomp/"+payload.type)
      .then(r=>r.data) 

    },
    async moveToUnpaidList(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/contenders/"+payload.id+"/movetounpaid")
      return ret.data
    },
    async moveToPaidList(payload) {
      const ret = await axios.post(endpoint + "competitions/"+payload.compid+"/contenders/"+payload.id+"/movetopaid")
      return ret.data
    },
    async updateContender(payload) {
      const ret = await axios.put(endpoint + "competitions/contenders/" + payload.id, payload)
      return ret.data
    },
    async saveContender(payload) {
      const ret = await axios.post(endpoint + "competitions/contenders", payload)
      return ret.data
    },
    async sendFinalists(payload) {
      const ret =  await axios.post(endpoint+"competitions/"+payload.compid+"/send_finalists",payload)
      return ret.data
    },
    async getResults(payload) {
      const ret =  await axios.get(endpoint+"competitions/"+payload+"/results/?format=json")
      return ret.data
    },
    async getContender(payload) {
      const ret =  await axios.get(endpoint+"competitions/contenders/"+payload)
      return ret.data
    },
    async removeAscent(payload) {
      const ret =  await axios.delete(endpoint+"competitions/"+payload.compid+"/ascents/"+payload.tickid)
      return ret.data
    },
    async addAscent(payload) {
      const ret =  await axios.post(endpoint+"competitions/"+payload.comp_id+"/ascents",payload)
      return ret.data
    },
    async searchContender(payload) {
      const ret =  await axios.post(endpoint+"competitions/"+payload.compid+"/contenders/search",payload)
      return ret.data
    },
    async getClimberAscents(payload) {
      const ret =  await axios.get(endpoint+"competitions/"+payload.compid+"/ascents/"+payload.contid)
      return ret.data
    },
}

