import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    all : [],
    row : null,
  }),
  mutations: {
    delete(state, payload) {
      state.all= state.all.filter(x => x.id != payload)
    },
    update(state, payload) {
      state.row= {...state.row,...payload}
    },
    row(state, payload) {
      state.row= payload
    },
    rows(state, payload) {
      state.all = payload
    },
    addRow(state, payload)  {
      state.all = [ payload, ...state.all ]
    },
    updateRows(state, payload) {
      state.all= state.all.map(x => {
        if (x.id == payload.id) {
          return payload
        }
        return x
      })
    },

  },
  actions: {
    async change_password({commit}, payload) {
      console.log(commit)
        const ret = await api.routesetters.change_password(payload)
        return ret
    },
    async save({commit}, payload) {
      let ret = null
      if (payload.id == '*new*') {
        ret = await api.routesetters.create(payload)
        commit('addRow',ret)
      } else {
        ret = await api.routesetters.save(payload)
        commit('row',ret)
        commit('updateRows',ret)
      }
    },
    async delete({commit}, payload) {
      await api.routesetters.delete(payload.id)
      commit('delete',payload.id)
    },
    async get({commit}, payload) {
      const ret = await api.routesetters.get(payload)
      commit('row',ret)
    },
    async all({commit} ) {
      const ret = await api.routesetters.all()
      commit('rows',ret)
    },
  },
  getters: {}
}