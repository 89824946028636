import axios from 'axios'
import {endpoint} from '@/helpers/problemator.js'

// Check if token is in local storage
let  token = localStorage.getItem('authToken');

if (token == undefined) {
  token = null
}

axios.defaults.headers.common = {'Authorization': `bearer ${token}`}


export default  {
    syncCircuitProblems : async (payload) => {
        const ret = await axios.post(endpoint + "circuits/"+payload.circuitid+"/problems/sync",payload)
        return ret.data
    },
    saveProblemCircuits: async (payload) => {
        const ret = await axios.post(endpoint + "problems/"+payload.id+"/circuits/save",payload)
        return ret.data
    },
    delete: async (payload) => {
        const ret = await axios.delete(endpoint + "circuits/"+payload)
        return ret.data
    },
    create: async (payload) => {
        const ret = await axios.post(endpoint + "circuits",payload)
        return ret.data
    },
    save: async (payload) => {
        const ret = await axios.put(endpoint + "circuits/"+payload.id,payload)
        return ret.data
    },
    get: async (payload) => {
        const ret = await axios.get(endpoint + "circuits/"+payload)
        return ret.data
    },
    all : async () => {
        const ret = await axios.get(endpoint + "circuits")
        return ret.data
    },
}