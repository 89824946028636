import { createStore } from "vuex";
import api from '@/api'
import competitions from './competitionModule.js'
import cups from './cupModule.js'
import walls from './wallModule.js'
import aging from './agings.store.js'
import routesetters from './routesetters.store.js'
import reports from './reports.store.js'
import floorplans from './floorplans.store.js'
import circuits from './circuits.store.js'
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from 'axios'

const getDefaultState = () => ({
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    loading : false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    authChecked : false,
    authenticated : false,
    authToken : null,
    problem : null, // Problem to edit
    circuitAscentsByClimber : null,
    problems : [],
    messages : [],
    gyms : [],
    authgyms : [], // Gyms the routesetter is allowed to change to
    problemMessages : [],
    basic : {
      gym : null,
      colours : [],
      paymentservices : [],
      walls : [],
      authors : [],
      attributes : [],
      user : null,
      grades : [],
      charts : {
        problem_types_radar : {'labels' : [], datasets : null},
        gradebars_boulder : {'labels' : [], datasets :null },
        gradebars_sport : {'labels' : [], datasets :null },
        ascents_boulder : {'labels' : [], datasets :null },
        ascents_sport : {'labels' : [], datasets :null },
        monthly_climbers : {'labels' : [], datasets :null },
      },
      stats : {
         users : 0,
          ticks : 0,
          newUsers : 0,
        likes : 0,
      }
    },
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    dialogs : {
      problemeditor_dialog : false,
      problemeditor : null,
    }
})

// initial state
const state = getDefaultState()


export default createStore({
  modules : {
    competitions,
    cups,
    walls,
    aging,
    routesetters,
    reports,
    floorplans,
    circuits,
  },
  state: getDefaultState(),
  mutations: {
    openDialog (state, payload) {
      state.dialogs[payload.name] = payload.value
      state.dialogs[payload.name+"_dialog"] = true
    },
    closeDialog (state, payload) {
      state.dialogs[payload] = null
      state.dialogs[payload+"_dialog"] = false
    },
    loading (state, payload) {
      state.loading = payload
    },
    isAbsolute (state, payload) {
      state.isAbsolute = payload
    },
    circuitAscentsByClimber (state, payload) {
      state.circuitAscentsByClimber = payload
    },
    removeProblemMessage (state, payload) {
      state.problemMessages = state.problemMessages.filter(m => m.id != payload)
    },
    problemMessages (state, payload) {
      state.problemMessages = payload
    },
    gym (state, payload) {
      state.basic = {...state.basic, ['gym'] : payload}
    },
    basic (state, payload) {
      state.basic = {...payload}
    },
    deleteWallProblems (state, payload) {
      state.problems = state.problems.filter(x => x.wallid != payload)
    },
    deleteProblem (state, payload) {
      state.problems = state.problems.filter(x => x.id != payload)
    },
    problem (state, payload) {
      state.problem = payload
    },
    authgyms (state, payload) {
      state.authgyms = payload
    },
    gyms (state, payload) {
      state.gyms = payload
    },

    authChecked (state, payload) {
      state.authChecked = payload
    },
    authToken (state, payload) {
      state.authToken = payload
    },
    authenticated (state, payload) {
      state.authenticated = payload
    },
    problems(state, payload) {
      state.problems = payload
    },
    messages(state, payload) {
      state.messages = payload
    },
    addProblem(state, payload) {
      state.problems = [payload,...state.problems]
    },
    updateProblem(state, payload) {
      state.problems = state.problems.map(p => {
        if(p.id ===payload.id)  {
          const ret = { ...p, ...payload }
          return ret
        }
        return p
      })
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setIsPinned(state, payload) {
      state.isPinned = payload;
    },
    color(state, payload) {
      state.color = payload;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  actions: {
    async  saveGymSettings({ commit } ,payload) {
      const ret = await api.saveGymSettings(payload)
      commit('gym',ret)
      return ret
    },
    async  removeAllProblems({ commit } ) {
      console.log(commit)
      return await api.removeAllProblems()
    },
    async  mergeClimbers({ commit }, payload) {
      console.log(commit)
      return await api.mergeClimbers(payload)
    },
    async changeGym({ commit }, payload) {
      console.log(commit)
      await api.changeGym(payload)
      //window.location.reload()
      const url = location.origin;
      const pathname = location.pathname;
      const hash = location.hash;

      location = url + pathname + '?application_refresh=' + (Math.random() * 100000) + hash;

    },
    async getCircuitAscents({commit}, payload) {
      const ret = await api.getCircuitAscentsByClimber(payload)
      commit('circuitAscentsByClimber',ret)
    },
    async executeProblemBulk({dispatch}, payload) {
      await api.executeProblemBulk(payload)
      // Reload problem list, so many things might have changed...
      dispatch('getProblems')
    },

    async approveProblemMessage({commit}, payload) {
      console.log(commit)
      await api.approveProblemMessage(payload)
    },
    async rejectProblemMessage({commit}, payload) {
      await api.rejectProblemMessage(payload)
      commit('removeProblemMessage',payload)
    },
    async markProblemMessageAsRead({commit}, payload) {
      await api.markProblemMessageAsRead(payload)
      commit('removeProblemMessage',payload)
    },
    async getProblemMessages({commit}) {
      const ret = await api.getProblemMessages()
      commit('problemMessages',ret)
    },
    async saveProblem({commit}, payload) {
      let ret = null
      if (payload.id != '*new*') {
        ret = await api.updateProblem(payload)
        // Update also problems array
        commit('updateProblem',ret)
      } else {
        ret = await api.saveProblem(payload)
        commit('addProblem',ret)
      }
      commit('problem',ret)
    },
    async getBasic({commit }) {
      commit('loading',true)
      const ret = await api.getBasic()
      // Check if not auth.
      if (typeof ret  == 'string') {
        commit('authenticated', false)
        commit('loading',false)
        alert(ret)
      } else {
        dayjs.extend(utc)
        dayjs.extend(timezone)
        dayjs.tz.setDefault(ret.gym.timezone)
        commit('basic',ret)
        commit('loading',false)
      }
    },
    async deleteProblem({ commit }, payload ) {
      const ret = await api.deleteProblem(payload)
      commit('deleteProblem',payload)
      return ret
    },
    async getProblem({ commit }, payload ) {
      const ret = await api.getProblem(payload)
      commit('problem',ret)
    },
    async checkAuth({ commit } ) {
      const ret = await api.checkAuth()
      if (ret.authorisation!= null && ret.authorisation.token != null) {
        const token = ret.authorisation.token
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        await localStorage.setItem('authToken',token)
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        commit('authenticated',token != null)
        commit('authToken',token)
      }
      
      commit('authenticated',ret != false)
      commit('authChecked',true)

    },
    async login({ commit },payload)  {
      const ret = await api.login(payload)
      if (ret.authorisation!= null && ret.authorisation.token != null) {
        const token = ret.authorisation.token
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        await localStorage.setItem('authToken',token)
        console.log("Token from login",token)
        commit('authenticated',token != null)
        commit('authToken',token)
      }
      return ret
    },
    logout({ commit },payload)  {
      api.logout(payload)
      localStorage.setItem('authToken',null)
      commit('authenticated',false)
      commit('authToken',null)
      Object.assign(state, getDefaultState())

    },
    async getProblems({ commit } ) {
      console.log("Fetching problems...")
      const ret = await api.getProblems()
      commit('problems',ret)
    },
    async getGyms({ commit }) {
      const gyms = await api.getGyms()
      commit('gyms',gyms)
    },
    async getAuthGyms({ commit }) {
      const gyms = await api.getAuthGyms()
      commit('authgyms',gyms)
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    }
  },
  getters: {
    authToken(state) {
      return state.authToken
    },
  }
});
