import axios from 'axios'
import {endpoint} from '@/helpers/problemator.js'

// Check if token is in local storage
let  token = localStorage.getItem('authToken');

if (token == undefined) {
  token = null
}

axios.defaults.headers.common = {'Authorization': `bearer ${token}`}


export default  {
    deleteWallProblems : async (payload) => {
        const ret = await axios.delete(endpoint + "wall/"+payload+"/problems")
        return ret.data
    },
    deleteWall : async (payload) => {
        const ret = await axios.delete(endpoint + "wall/"+payload)
        return ret.data
    },
    createWall : async (payload) => {
        const ret = await axios.post(endpoint + "wall",payload)
        return ret.data
    },
    saveWall : async (payload) => {
        const ret = await axios.put(endpoint + "wall/"+payload.id,payload)
        return ret.data
    },
    getWall : async (payload) => {
        const ret = await axios.get(endpoint + "wall/"+payload)
        return ret.data
    },
    getWalls : async () => {
        const ret = await axios.get(endpoint + "wall")
        return ret.data
    },
}