import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    all : [],
    row : null,
  }),
  getters : {

    publicCircuits(state) {
      return  state.all.filter(c => c.ispublic == 1)
    },
    privateCircuits(state) {
      return  state.all.filter(c => c.ispublic == 0)
    },

  },
  mutations: {
    updateProblem(state, payload) {
      console.log("TBD",state,payload)
    },
    updateCircuits (state, payload) {
      state.all = state.all.map(p => {
        if(p.id ===payload.id) 
          return {
            ...p, ...payload,
          }
        return p
      })

      state.all = {...state.all, [payload.id] : payload}
    },
    delete(state, payload) {
      state.all= state.all.filter(x => x.id != payload)
    },
    update(state, payload) {
      state.row= {...state.row,...payload}
    },
    setCircuitProblems(state, payload) {
      state.row.problems = payload
    },
    row(state, payload) {
      state.row= payload
    },
    rows(state, payload) {
      state.all = payload
    },
    addRow(state, payload)  {
      state.all = [ payload, ...state.all ]
    },
    updateRows(state, payload) {
      state.all= state.all.map(x => {
        if (x.id == payload.id) {
          return {...x, ...payload}
        }
        return x
      })
    },

  },
  actions: {
    async saveProblemCircuits({commit }, payload) {
      const ret = await api.circuits.saveProblemCircuits(payload)
      // Need to update problem's circuits
      commit('updateProblem',ret.problem,{root : true})
      return ret

    },
    async delete({commit}, payload) {
      await api.circuits.delete(payload.id)
      commit('delete',payload.id)
    },
    async get({commit}, payload) {
      const ret = await api.circuits.get(payload)
      commit('row',ret)
    },
    async all({commit} ) {
      const ret = await api.circuits.all()
      commit('rows',ret)
    },
    async save({commit}, payload) {
      let ret = null
      if (payload.id == '*new*') {
        ret = await api.circuits.create(payload)
        commit('addRow',ret.circuit)
      } else {
        ret = await api.circuits.save(payload)
        commit('row',ret.circuit)
        commit('updateRows',ret.circuit)
      }
      return ret
    },
    async getCircuit({commit}, payload) {
      const ret = await api.getCircuit(payload)
      commit('row',ret)
    },
    async getCircuits({commit}, payload) {
      const ret = await api.getCircuits(payload)
      commit('rows',ret.data)
    },
    async syncCircuitProblems({commit}, payload) {
      const ret = await api.circuits.syncCircuitProblems(payload)
      commit('setCircuitProblems',ret.data)
      return ret.data
    },

  },
}