import axios from 'axios'
import { userCountry } from '@/helpers'
import {endpoint, publicendpoint} from '@/helpers/problemator.js'
import cups from './api.cups.js'
import competitions from './api.comps.js'
import reports from './api.reports.js'
import walls from './api.walls.js'
import aging from './api.aging'
import routesetters from './api.users'
import floorplans from './api.floorplans'
import circuits from './api.circuits'

// Check if token is in local storage
let  token = localStorage.getItem('authToken');
console.log("token",token)
if (token == undefined) {
  token = null
}
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

export default {
  cups,
  competitions,
  walls,
  reports,
  aging,
  routesetters,
  floorplans,
  circuits,
  async saveGymSettings(payload) {
    const ret =  await axios.post(endpoint+"gym/"+payload.id+"/settings",payload )
    return ret.data
  },
  async deleteProblem(payload) {
    const ret =  await axios.delete(endpoint+"problems/"+payload )
    return ret.data
  },
  async removeAllProblems() {
    const ret =  await axios.delete(endpoint+"gym/delete_all_problems" )
    return ret.data
  },
  async mergeClimbers(payload) {
    const ret =  await axios.post(endpoint+"climber/merge", payload)
    return ret.data
  },
  async changeGym(payload) {
    const ret =  await axios.post(endpoint+"gym/"+payload+"/change")
    return ret.data
  },
  async getCompetitions() {
    const ret =  await axios.get(endpoint+"competitions/")
    return ret.data
  },
  async getCompetition(payload) {
    const ret =  await axios.get(endpoint+"competitions/"+payload)
    return ret.data
  },
  async getCircuitAscentsByClimber(payload) {
    const ret =  await axios.get(endpoint+"circuits/"+payload+"/ascents_by_climber")
    return ret.data
  },
  async updateCircuit(payload) {
    const ret = await axios.put(endpoint+"circuits/"+payload.id,payload)
    return ret.data
  },
  async saveCircuit(payload) {
    const ret = await axios.post(endpoint+"circuits",payload)
    return ret.data
  },
  async getCircuit(payload) {
    const ret =  await axios.get(endpoint+"circuits/"+payload)
    return ret.data
  },
  async getCircuits() {
    const ret =  await axios.get(endpoint+"circuits")
    return ret
  },
  async executeProblemBulk(payload) {
    const ret =  await axios.post(endpoint+"problems/bulk",payload)
    return ret
  },
  async rejectProblemMessage(payload) {
    const ret =  await axios.post(endpoint+"messages/"+payload+"/reject")
    return ret.data
  },
  async approveProblemMessage(payload) {
    const ret =  await axios.post(endpoint+"messages/"+payload+"/approve")
    return ret.data
  },
  async markProblemMessageAsRead(payload) {
    const ret =  await axios.post(endpoint+"messages/"+payload+"/markread")
    return ret.data
  },
  async getProblemMessages() {
    const ret =  await axios.get(endpoint+"problems/messages")
    return ret.data
  },
  async updateProblem(payload) {
    const ret = await axios.put(endpoint+"problems/"+payload.id,payload)
    return ret.data
  },
  async saveProblem(payload) {
    const ret = await axios.post(endpoint+"problems",payload)
    return ret.data
  },
  async getBasic() {
    const ret =  await axios.get(endpoint+"basic")
    return ret.data
  },
  async getProblem(id) {
    const ret = await axios.get(endpoint+"problems/"+id)
    return ret.data
  },
  async getAuthGyms() {
      const ret = await axios.get(publicendpoint+"authgyms")
      const json = ret.data
      return json.gyms
  },
  async getGyms() {
      const ret = await axios.get(publicendpoint+"gyms?country="+userCountry())
      const json = ret.data
      return json.gyms
  },
  logout(payload) {
    const ret = axios.post(publicendpoint+"routesetter/logout",payload)
    return ret.data
  },
  async login(payload) {
       const ret = await axios.post(publicendpoint+"routesetter/login",payload)
       return ret.data
  },
  async checkAuth() {
    if (token == null || token == 'null') {
      return false
    } else {
      try {
       const ret =  await axios.post(publicendpoint+"routesetter/refresh",{ token })
       return ret.data
      } catch (e) {
        return false
      }
    }
  },
  async getProblems() {
     const ret = await axios.get(endpoint+"problems")
    return ret.data
  },
};
