import dayjs from 'dayjs'

const date_format = (date,format='YYYY-MM-DD') => {
    if (date == null) {
        return ""
    }
    return dayjs(date).format(format)
}
//const ct = require('countries-and-timezones')
import ct from 'countries-and-timezones'
const userCountry = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezone = ct.getCountryForTimezone(tz)
  return timezone.name
}
const shuffleArray = (arr) => {
  const newArr = arr.slice()
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
  }
  return newArr
}
/**
 * Gets next key in serie. eg. A -> B. BC -> BD etc. 
 * @param {String} current Key
 * @returns  next key in line
 */
const getNextKey = function (key) {
  if (key === 'Z' || key === 'z') {
    return String.fromCharCode(key.charCodeAt() - 25) + String.fromCharCode(key.charCodeAt() - 25) // AA or aa
  } else {
    var lastChar = key.slice(-1)
    var sub = key.slice(0, -1)
    if (lastChar === 'Z' || lastChar === 'z') {
      // If a string of length > 1 ends in Z/z,
      // increment the string (excluding the last Z/z) recursively,
      // and append A/a (depending on casing) to it
      return getNextKey(sub) + String.fromCharCode(lastChar.charCodeAt() - 25)
    } else {
      // (take till last char) append with (increment last char)
      return sub + String.fromCharCode(lastChar.charCodeAt() + 1)
    }
  }
}
const randomPassword = (length) => {
  var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890"
  var pass = ""
  for (var x = 0; x < length; x++) {
    var i = Math.floor(Math.random() * chars.length)
    pass += chars.charAt(i)
  }
  return pass
}

export {
  randomPassword,
  shuffleArray,
  userCountry,
  getNextKey,
  date_format,

}