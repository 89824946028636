import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    all : [],
    row : null,
  }),
  mutations: {
    delete(state, payload) {
      state.all= state.all.filter(x => x.id != payload)
    },
    update(state, payload) {
      state.row= {...state.row,...payload}
    },
    row(state, payload) {
      state.row= payload
    },
    rows(state, payload) {
      state.all = payload
    },
    addRow(state, payload)  {
      state.all = [ payload, ...state.all ]
    },
    updateRows(state, payload) {
      state.all= state.all.map(x => {
        if (x.id == payload.id) {
          return payload
        }
        return x
      })
    },

  },
  actions: {
    async save({commit}, payload) {
      let ret = null
      if (payload.id == '*new*') {
        ret = await api.aging.create(payload)
        commit('addRow',ret)
      } else {
        ret = await api.aging.save(payload)
        commit('update',ret)
        commit('updateRows',ret)
      }
      commit('row',ret)
    },
    async delete({commit}, payload) {
      await api.aging.delete(payload.id)
      commit('delete',payload.id)
    },
    async get({commit}, payload) {
      const ret = await api.aging.get(payload)
      commit('row',ret)
    },
    async all({commit} ) {
      const ret = await api.aging.all()
      commit('rows',ret)
    },
  },
  getters: {}
}