<template>
    <a @click.prevent="activateEdit">
        <span v-if="!clicked" class="border-1 border-dashed border-radius-md border-blue-500 px-3 py-1" >
            <slot />
        </span>
        <span v-if="clicked">
            <div class="flex flex-row">
                <div class="input-group">
                    <input ref="inputField" type="text" class="  mb-3 flex-grow form-control" v-model="modelValue" />
                    <!-- .stop is needed so that the event stops bubbling up to click handler which runs the activateEdit  -->
                    <button class="btn bnt-sm btn-success" @click.stop="onSave">{{ okTitle }}</button>
                    <button class="btn bnt-sm btn-danger" @click.stop="onCancel">Cancel</button>
                </div>
            </div>
        </span>

    </a>
</template>

<script setup >
    import { ref } from 'vue'
    import { useSlots } from 'vue';
    const slots = useSlots()
    const children = slots.default()[0].children
    const inputField = ref(null)

    const clicked = ref(false)
    const props = defineProps({ 
        id : { type : null, required : true},
        okTitle : {type : String, default : 'OK'},
    })
    const emit = defineEmits(['ok','cancel'])
    const activateEdit = () => {
        clicked.value=true
        // Focuses the input field and selects the text, but only 
        // after a little timeout, as this is not doable when this
        // function is still running.
        setTimeout(() => {
            const input = inputField.value
            input.focus()
            input.select()
        },20)
    }

    const modelValue = ref(children)
    const onSave =() => {
        clicked.value = false
        emit('ok',{id : props.id, value : modelValue.value})
    }
    const onCancel = () => {
        clicked.value = false
        emit('cancel',{id : props.id, value : modelValue.value})
    }

</script>

<style scoped>

</style>