import * as dotenv from 'dotenv' 
dotenv.config()

const basicRouteTypes = [
    {id : "boulder", label : 'Boulder'},
    {id: "sport", label : "Sport"},
] 
const routeTypes = [
    {id : "qualifying", label : 'Qualifying'},
    {id: "semifinal", label : "Semifinal"},
    {id: "final", label : "Final"},
] 
const mapboxMapKey = process.env.VUE_APP_MAPBOX_MAP_KEY
const endpoint = process.env.VUE_APP_ENDPOINT
const webendpoint = process.env.VUE_APP_WEB_ENDPOINT
const publicendpoint =process.env.VUE_APP_PUBLIC_ENDPOINT
export { routeTypes,endpoint, webendpoint , publicendpoint, basicRouteTypes, mapboxMapKey}
