import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    all : [],
    wall : null,
  }),
  mutations: {
    deleteWallProblems(state, payload) {
      state.all = state.all.map(wall => {
        if (wall.id == payload) {
          return {...wall, ['active_problems'] : []}
        }
        return wall

      })
    },
    deleteWall(state, payload) {
      state.all= state.all.filter(x => x.id != payload)
    },
    updateWall(state, payload) {
      state.cup= {...state.cup,...payload}
    },
    wall(state, payload) {
      state.wall= payload
    },
    walls(state, payload) {
      state.all = payload
    },
    addWall(state, payload)  {
      state.all = [ payload, ...state.all ]
    },
    updateWalls(state, payload) {
      state.all= state.all.map(x => {
        if (x.id == payload.id) {
          return payload
        }
        return x
      })
    },

  },
  actions: {
    async saveWall({commit}, payload) {
      let ret = null
      if (payload.id == '*new*') {
        ret = await api.walls.createWall(payload)
        commit('addWall',ret.wall)
      } else {
        ret = await api.walls.saveWall(payload)
        commit('updateWalls',ret.wall)
      }
      commit('wall',ret.wall)
    },
    async deleteWallProblems({commit}, payload) {
      await api.walls.deleteWallProblems(payload.id)
      commit('deleteWallProblems', payload.id, { root: true })
      commit('deleteWallProblems',payload.id)
    },
    async deleteWall({commit}, payload) {
      await api.walls.deleteWall(payload.id)
      commit('deleteWall',payload.id)
    },
    async getWall({commit}, payload) {
      const ret = await api.walls.getWall(payload)
      commit('wall',ret)
    },
    async all({commit} ) {
      const ret = await api.walls.getWalls()
      commit('walls',ret)
    },
  },
  getters: {}
}